<template>
  <div class="card card-custom card-transparent">
   
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-4"
        id="kt_wizard_v4"
        data-wizard-state="step-first"
      >
        <!--begin: Wizard Nav-->
        <div class="wizard-nav">
          <div class="wizard-steps" style="justify-content: left;">
            <div
              class="wizard-step"
              data-wizard-type="step"
              data-wizard-state="current"
            >
              <div class="wizard-wrapper">
                <div class="wizard-number">
                  1
                </div>
                <div class="wizard-label">
                  <div class="wizard-title">
                    Data Notaris Kerjasama
                  </div>
                  <div class="wizard-desc">
                    Tambah Data Notaris Kerjasama
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav -->

        <!--begin: Wizard Body-->
        <v-app>
          <div class="card card-custom card-shadowless rounded-top-0">
            <div class="card-body p-0">
              <div class="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
                <div class="col-xl-12 col-xxl-12">
                  <!--begin: Wizard Form-->
                  <!-- <form class="form mt-0 mt-lg-10" id="kt_form"> -->
                    <v-progress-linear
                      indeterminate
                      color="pink darken-2"
                      v-show="!loadForm"
                      style="height:15px;"
                    ><div><span>Proses Data...</span></div></v-progress-linear>
                    <!--begin: Wizard Step 1-->
                  <v-form ref="form" v-model="valid" v-show="loadForm" lazy-validation>
                    <div
                      class="pb-5"
                      data-wizard-type="step-content"
                      data-wizard-state="current"
                    >
                    <div class="row">
                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                        class="pb-0 mb-0"
                      >
                      <div class="form-group">
                        <label>Nama</label>
                        <v-text-field
                          v-model="formItem.nama"
                          :rules="idFormBiasa"
                          id="nama"
                          label="Nama"
                          clearable
                          required
                          solo
                        ></v-text-field>
                      </div>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                        class="pb-0 mb-0"
                      >
                      <div class="form-group">
                        <label>Alamat</label>
                        <v-textarea
                          v-model="formItem.alamat"
                          label="Alamat"
                          clearable
                          solo
                          ></v-textarea>
                      </div>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                        class="pb-0 mb-0"
                      >
                      <div class="form-group">
                        <label>Nama Kontak</label>
                        <v-text-field
                          v-model="formItem.contact"
                          :rules="idFormBiasa"
                          id="contact"
                          label="Nama Kontak"
                          clearable
                          required
                          solo
                        ></v-text-field>
                      </div>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                        class="pb-0 mb-0"
                      >
                      <div class="form-group">
                        <label>Email</label>
                        <v-text-field
                          v-model="formItem.email"
                          :rules="idFormBiasa"
                          id="email"
                          label="Email"
                          clearable
                          required
                          solo
                        ></v-text-field>
                      </div>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                        class="pb-0 mb-0"
                      >
                      <div class="form-group">
                        <label>Nomor Telepon/HP</label>
                        <v-text-field
                          v-model="formItem.no_hp"
                          type="number"
                          :rules="idFormBiasa"
                          id="no_hp"
                          label="Nomor Telepon/HP"
                          clearable
                          required
                          solo
                        ></v-text-field>
                      </div>
                      </v-col>
                    </div>
                    </div>
                  </v-form>
                <!-- </div>
              </div> -->
            <!--end: Wizard Step 1-->

                    <!--begin: Wizard Actions -->
                      <div class="d-flex justify-content-between border-top pt-10" v-show="loadForm">
                        <div class="mr-2">
                          <v-btn
                              rounded
                              color="#ffa69e"
                              style="border-radius: 30px; border: 2px solid white; font-size: 13px; height: 50px;"
                              @click="goTable()"
                          >
                              Kembali Ke Table
                          </v-btn>
                        </div>
                      <div>
                         <v-btn
                              rounded
                              color="#fec5bb"
                              style="border-radius: 30px; border: 2px solid white; font-size: 13px; height: 50px;"
                              @click="save(formItem)"
                          >
                              Simpan
                          </v-btn>
                      </div>
                    </div>
                    <!--end: Wizard Actions -->
                  <!-- </form> -->
                  <!--end: Wizard Form-->
                </div>
              </div>
            </div>
          </div>
        </v-app>
        <!--end: Wizard Bpdy-->
      </div>
      <!--end: Wizard-->
    </div>
    
  </div>
</template>

<style lang="css">
    .v-application--wrap{ min-height: 0px; }
    .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
        background-color: #ffa69e;
        font-size: 13px;
        vertical-align: inherit;
        margin-top: 5%;
    }
</style>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-4.scss";
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";
import Services from "@/core/services/notasis-api/Services";
import storagelocal from "@/core/services/storagelocal.service";

export default {
  name: "Wizard-4",
  data(){
    return{
      valid: true,
      formItem: {
        nama: '',
        alamat: '',
        contact: '',
        email: '',
        no_hp: '',
      },
      
      idFormBiasa: [
        v => !!v || 'Mohon Diisi !'
      ],
      flag: "",
      // ableWizard: true,
      idData: '',
      options: {
        page: 1,
        itemsPerPage: 5,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false
      },
      dKerjasama: [],
      tdKerjasama: 0,
      progressBar: false,
      loadForm: false,
      isFirstLoad: true
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Wizard", route: "wizard-1" },
      { title: "Wizard-4" }
    ]);
    
    // console.log(this.$route.query.dPerseroan)
    this.flag = this.$route.query.flag

    // Initialize form wizard
    const wizard = new KTWizard("kt_wizard_v4", {
      startStep: 1, // initial active step number
      clickableSteps: this.flag == "isUpdate" ? true : false // allow step clicking
    });

    // Validation before going to next page
    wizard.on("beforeNext", function(/*wizardObj*/) {
      // validate the form and use below function to stop the wizard's step
      // wizardObj.stop();
    });

    // Change event
    wizard.on("change", function(/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
  },
  watch: {
    options: {
        async handler (val) {
            val
            if(!this.isFirstLoad && this.flag == "isUpdate") 
              await this.getDataKerjasama()
        },
        deep: true,
    },
    flag(val){
      if(val == 'isCreate'){
        this.isCreate()
      }else if(val == 'isUpdate' && this.isFirstLoad){
        this.idData = this.$route.query.idData
        this.load2()
      }
    }
  },
  methods: {
    submit: function(e) {
      e.preventDefault();
      Swal.fire({
        title: "",
        text: "The application has been successfully submitted!",
        icon: "success",
        confirmButtonClass: "btn btn-secondary"
      });
    },
    isCreate(){
      this.loadForm = true
    },
    async save (data_save){
      var saveObj = {}
      var _this = this
      if( _this.$refs.form.validate()){
        if(this.flag == 'isCreate'){
          saveObj = Object.assign({}, data_save)
          saveObj.UID = storagelocal.getLocalStorage('UID')
          saveObj.Token = storagelocal.getLocalStorage('token')
          saveObj.Trigger = 'C'
        }else{
          saveObj = Object.assign({}, data_save)
          saveObj.UID = storagelocal.getLocalStorage('UID')
          saveObj.Token = storagelocal.getLocalStorage('token')
          saveObj.Trigger = 'U'
          saveObj.id = this.idData
        }
        Promise.all([await this.saveAll(saveObj)])
          .then((results8)=>{
          results8
          // this.loadPage()
        })
      }
    },
    saveAll(saveObj){
      return new Promise(resolve => {
          resolve, saveObj
            // this.ableWizard = false;
            Services.PostData(ApiService, "m_notaris", saveObj, response=>{
              resolve(response.data);
              if(response.status == 1000){
                Swal.fire({
                    title: "Berhasil",
                    text: "Sukses Menambah Data",
                    icon: "success",
                    heightAuto: false,
                    timer: 2000
                });
                // this.$router.push({name: 'tablekerjasama'})
              }else{
                Swal.fire({
                    title: "Berhasil",
                    text: "Gagal Menambah Data",
                    icon: "info",
                    heightAuto: false,
                    timer: 2000
                });
              }
            }, err =>{
              err
            }) 
        });
    },
    getDataKerjasama(){
      return new Promise(resolve => {
      var mydata = {
          UID 		        : storagelocal.getLocalStorage('UID'),
          Token		        : storagelocal.getLocalStorage('token'),
          Trigger	        : 'R',
          options         : this.options,
          id              : this.idData
      }
      this.loadForm = false
      Services.PostData(ApiService, "m_notaris", mydata, async response=>{
        if(response.status == 1000){
          this.loadForm = true
          var responseCace = response.data[0]
          this.formItem = Object.assign({}, responseCace)
        }else{
          Swal.fire({
              title: "Gagal",
              text: "Gagal Memuat Data",
              icon: "warning",
              heightAuto: false,
          });
        }
          resolve(responseCace);
          }, err =>{
          err
          })
      });
    },
    goTable(){
      this.$router.push({name: 'tablekerjasama'})
    },
    async load2(){
      Promise.all([
                    await this.getDataKerjasama()
                  ])
      .then( (results)=> {
       
        this.isFirstLoad = false
        results
      })
    },
  }
};
</script>
